import React, { useState, useEffect, FC } from "react";
import { AppBar, Toolbar, Typography, IconButton, Avatar, Menu, MenuItem, ListItemIcon, ListItemText, Button, Box, Container, Hidden, Divider } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import LaunchIcon from "@mui/icons-material/Launch";
import { AccountCircle, ExitToApp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

export interface NavbarProps {
  isLoggedIn: boolean;
  onSignInClick: () => void;
}

const Navbar: FC<NavbarProps> = ({ isLoggedIn, onSignInClick }) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10;

      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <div>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: "white",
          color: "#171e37",
          boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
          transform: visible ? "translateY(0)" : "translateY(-100%)", // This will hide the AppBar when scrolling down
          transition: "transform 0.3s",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: isLoggedIn && window.innerWidth <= 960 ? "center" : "space-between", // check if the screen width is less than or equal to 960 pixels (typical breakpoint for medium-sized devices)
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                maxWidth: 200,
                minWidth: 50,
              }}
            >
              <Link to="https://betting-screener.com">
                <img src={`${process.env.REACT_APP_PUBLIC_URL}/logo/logo_full_dark.png`} alt="Betting-screener" />
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Hidden mdDown>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button color="info" sx={{ mr: 2 }} endIcon={<LaunchIcon />} href="https://betting-screener.com/bot/" target="_blank" rel="noopener noreferrer">
                    Telegram
                  </Button>

                  <ScrollLink to="legendSection" smooth={true} duration={500}>
                    <Button color="inherit" sx={{ mr: 2 }}>
                      Legend
                    </Button>
                  </ScrollLink>

                  <ScrollLink to="dataSection" smooth={true} duration={500}>
                    <Button color="inherit" sx={{ mr: 2 }}>
                      Data
                    </Button>
                  </ScrollLink>

                  <ScrollLink to="faqSection" smooth={true} duration={500}>
                    <Button color="inherit" sx={{ mr: 2 }}>
                      FAQ
                    </Button>
                  </ScrollLink>
                </Box>
              </Hidden>
              {!isLoggedIn && (
                <Button color="info" variant="outlined" onClick={onSignInClick} sx={{ ml: 2 }} endIcon={<LoginIcon />}>
                  Sign In
                </Button>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Navbar;
