import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { db, analytics, logEvent } from "../firebase";
import { DataSnapshot } from "firebase/database";
import { ref, onValue, off, update, get } from "firebase/database";
import { DataGrid, GridColDef, GridRowParams, GridRowsProp, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { Alert, AlertTitle, Box, Divider, Typography, useTheme } from "@mui/material";
import "./MatchesTable.css";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MatchChart from "./MatchChart";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
            <path className="ant-empty-img-1" d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" />
            <path className="ant-empty-img-2" d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>There are no Live Games at the moment</Box>
    </StyledGridOverlay>
  );
}

function LogInRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
            <path className="ant-empty-img-1" d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" />
            <path className="ant-empty-img-2" d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Sign in to see this data</Box>
    </StyledGridOverlay>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type MyTableProps = {
  isLoggedIn: boolean;
};

const MatchesTable: React.FC<MyTableProps> = ({ isLoggedIn }) => {
  const isMobile = window.innerWidth <= 600;
  const [rows, setRows] = React.useState<GridRowsProp>([]);

  type Match = {
    timep: string;
    homescore: string;
    awayscore: string;
    homet: string;
    awayt: string;
    hpi1: string;
    api1: string;
    hpi2: string;
    api2: string;
    homeposs: string;
    awayposs: string;
    hshotsont45: string;
    ashotsont45: string;
    shotsont: string;
    shotofft: string;
    sinbox: string;
    dattack: string;
    attack: string;
    homecorners: string;
    awaycorners: string;
    ycards: string;
    rcards: string;
    "10poss": string;
    "10shotofft": string;
    "10dattack": string;
    "10corners": string;
    "10hshotsont": string;
    "10ashotsont": string;
    totalMatched: number;
    hodd: number;
    aodd: number;

    overall_score: number;
    overall_s1: number;
    overall_s2: number;
    overall_pos: number;
    overall_sot: number;
    overall_sofft: number;
    overall_dattack: number;
    overall_attack: number;
    overall_corners: number;
    overall_ycards: number;
    overall_rcards: number;
  };

  type TableRow = {
    id: number;
    time: string;
    homescore: string;
    awayscore: string;
    homet: string;
    awayt: string;
    hpi1: string;
    api1: string;
    hpi2: string;
    api2: string;
    homeposs: string;
    awayposs: string;
    hshotsont45: string;
    ashotsont45: string;
    shotsont: string;
    shotofft: string;
    sinbox: string;
    dattack: string;
    attack: string;
    homecorners: string;
    awaycorners: string;
    ycards: string;
    rcards: string;
    "10poss": string;
    "10shotofft": string;
    "10dattack": string;
    "10corners": string;
    "10hshotsont": string;
    "10ashotsont": string;
    totalMatched: number;
    hodd: number;
    aodd: number;

    overall_score: number;
    overall_s1: number;
    overall_s2: number;
    overall_pos: number;
    overall_sot: number;
    overall_sofft: number;
    overall_dattack: number;
    overall_attack: number;
    overall_corners: number;
    overall_ycards: number;
    overall_rcards: number;
  };

  /* const fetchData = async () => {
    if (!isLoggedIn) {
      return;
    }

    try {
      const response = await axios.get(url);

      const matchesData: Match[] = response.data;
      console.log(matchesData);
      const processedRows: TableRow[] = [];

      matchesData.forEach((match: Match, idx: number) => {
        const row: TableRow = {
          id: idx,
          time: match.timep,
          homescore: match.homescore,
          awayscore: match.awayscore,
          homet: match.homet,
          awayt: match.awayt,
          hpi1: match.hpi1,
          api1: match.api1,
          hpi2: match.hpi2,
          api2: match.api2,
          homeposs: `${match.homeposs ?? ""}`,
          awayposs: `${match.awayposs ?? ""}`,
          hshotsont45: match.hshotsont45,
          ashotsont45: match.ashotsont45,
          shotofft: match.shotofft,
          shotsont: match.shotsont,
          sinbox: match.sinbox,
          dattack: match.dattack,
          attack: match.attack,
          homecorners: match.homecorners,
          awaycorners: match.awaycorners,
          ycards: match.ycards,
          rcards: match.rcards,
          "10poss": match["10poss"],
          "10shotofft": match["10shotofft"],
          "10dattack": match["10dattack"],
          "10corners": match["10corners"],
          "10hshotsont": match["10hshotsont"],
          "10ashotsont": match["10ashotsont"],
          totalMatched: match.totalMatched,
          hodd: match.hodd,
          aodd: match.aodd,
          overall_score: match.overall_score,
          overall_s1: match.overall_s1,
          overall_s2: match.overall_s2,
          overall_pos: match.overall_pos,
          overall_sot: match.overall_sot,
          overall_sofft: match.overall_sofft,
          overall_dattack: match.overall_dattack,
          overall_attack: match.overall_attack,
          overall_corners: match.overall_corners,
          overall_ycards: match.overall_ycards,
          overall_rcards: match.overall_rcards,
        };

        processedRows.push(row);
        console.log("processed", row);
      });

      setRows(processedRows);
      console.log("all Processed", processedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }; */

  const [inplayData, setInplayData] = useState<Match[] | null>(null);

  React.useEffect(() => {
    /* fetchData();
    const intervalId = setInterval(fetchData, 10000);

    return () => {
      clearInterval(intervalId);
    }; */

    const inplayRef = ref(db, "/data/inplay");
    onValue(inplayRef, (snapshot: DataSnapshot) => {
      const inplayData = snapshot.val();

      if (!isLoggedIn || !Array.isArray(inplayData)) {
        return;
      }

      const processedRows: TableRow[] = [];

      inplayData.forEach((match: Match, idx: number) => {
        const row: TableRow = {
          id: idx,
          time: match.timep,
          homescore: match.homescore,
          awayscore: match.awayscore,
          homet: match.homet,
          awayt: match.awayt,
          hpi1: match.hpi1,
          api1: match.api1,
          hpi2: match.hpi2,
          api2: match.api2,
          homeposs: `${match.homeposs ?? ""}`,
          awayposs: `${match.awayposs ?? ""}`,
          hshotsont45: match.hshotsont45,
          ashotsont45: match.ashotsont45,
          shotsont: match.shotsont,
          shotofft: match.shotofft,
          sinbox: match.sinbox,
          dattack: match.dattack,
          attack: match.attack,
          homecorners: match.homecorners,
          awaycorners: match.awaycorners,
          ycards: match.ycards,
          rcards: match.rcards,
          "10poss": match["10poss"],
          "10shotofft": match["10shotofft"],
          "10dattack": match["10dattack"],
          "10corners": match["10corners"],
          "10hshotsont": match["10hshotsont"],
          "10ashotsont": match["10ashotsont"],
          totalMatched: match.totalMatched,
          hodd: match.hodd,
          aodd: match.aodd,
          overall_score: match.overall_score,
          overall_s1: match.overall_s1,
          overall_s2: match.overall_s2,
          overall_pos: match.overall_pos,
          overall_sot: match.overall_sot,
          overall_sofft: match.overall_sofft,
          overall_dattack: match.overall_dattack,
          overall_attack: match.overall_attack,
          overall_corners: match.overall_corners,
          overall_ycards: match.overall_ycards,
          overall_rcards: match.overall_rcards,
        };

        processedRows.push(row);
      });

      setRows(processedRows);
    });
  }, [isLoggedIn]);

  const safeSplit = (value: string | undefined, separator: string, index: number) => {
    if (value?.includes("<br>")) separator = "<br>";
    if (value?.includes("<br />")) separator = "<br />";
    if (typeof value === "string") {
      if (value.includes("<span class=away>")) {
        return value.replace("<span class=away>", "").replace("</span>", "").replace("&nbsp;&nbsp;", "").split(separator)[index];
      } else if (value.includes("<span class=home>")) {
        return value.replace("<span class=home>", "").replace("</span>", "").replace("&nbsp;&nbsp;", "").split(separator)[index];
      } else {
        return value.replace("&nbsp;&nbsp;", "").split(separator)[index];
      }
    }
    return "-";
  };

  const parseDataset = (dataset: TableRow, team: number) => {
    return {
      possession: team === 0 ? parseInt(dataset.homeposs) : parseInt(dataset.awayposs),
      shotsOnTarget: team === 0 ? parseInt(dataset.hshotsont45) : parseInt(dataset.ashotsont45),
      shotsOffTarget: parseInt(safeSplit(dataset.shotofft, "<br />", team) ?? 0),
      attacks: parseInt(safeSplit(dataset.attack, "<br />", team) ?? 0),
      dangerousAttacks: parseInt(safeSplit(dataset.dattack, "<br />", team) ?? 0),
      corners: team === 0 ? parseInt(dataset.homecorners) : parseInt(dataset.awaycorners),
      yellowCards: parseInt(safeSplit(dataset.ycards, "<br />", team) ?? 0),
      redCards: parseInt(safeSplit(dataset.rcards, "<br />", team) ?? 0),
    };
  };

  const columns: GridColDef[] = [
    {
      field: "time",
      headerName: "Time",
      sortable: true,
      align: "center",
      headerAlign: "center",
      width: isMobile ? 10 : 30,
    },
    {
      field: "overall_score",
      headerName: "Score",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: isMobile ? 10 : 80,
      renderCell: (params) => (
        <div>
          <div>{params.row.homescore}</div>
          <Divider />
          <div>{params.row.awayscore}</div>
        </div>
      ),
    },
    {
      field: "homet",
      headerName: "Teams (Clickable)",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: isMobile ? 120 : 200,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            handleRowClick(params.row);
          }}
        >
          <div>{params.row.homet}</div>
          <Divider />
          <div>{params.row.awayt}</div>
        </div>
      ),
    },
    {
      field: "overall_s1",
      headerName: "Screener Score 1",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: isMobile ? 10 : 80,
      description: "Uses data from the last 10 minutes.",
      renderHeader: (params) => <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/pi2.png`} alt="Screener Score 1" className="w-10" />,
      renderCell: (params) => {
        return (
          <div>
            <div
              style={{
                backgroundColor: `${params.row.hpi1 > 55 ? "rgba(0,205,145,0.8)" : "rgba(255,255,255,0"}`,
              }}
            >
              <b>{parseInt(params.row.time) > 10 ? params.row.hpi1 : "-"}</b>
            </div>
            <Divider />
            <div
              style={{
                backgroundColor: `${params.row.api1 > 55 ? "rgba(0,205,145,0.8)" : "rgba(255,255,255,0"}`,
              }}
            >
              <b>{parseInt(params.row.time) > 10 ? params.row.api1 : "-"}</b>
            </div>
          </div>
        );
      },
    },
    {
      field: "overall_s2",
      headerName: "Screener Score 2",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: isMobile ? 10 : 80,
      description: "Uses data from the last 10 minutes.",

      renderHeader: (params) => <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/pi1.png`} alt="Screener Score 2" className="w-10" />,

      renderCell: (params) => {
        return (
          <div>
            <div
              style={{
                backgroundColor: `${params.row.hpi2 > 55 ? "rgba(0,205,145,0.8)" : "rgba(255,255,255,0"}`,
              }}
            >
              <div>
                <b>{parseInt(params.row.time) > 10 ? params.row.hpi2 : "-"}</b>
              </div>
              <Divider />
              <div
                style={{
                  backgroundColor: `${params.row.api2 > 55 ? "rgba(0,205,145,0.8)" : "rgba(255,255,255,0"}`,
                }}
              >
                <b>{parseInt(params.row.time) > 10 ? params.row.api2 : "-"}</b>
              </div>
            </div>
            <Divider orientation="vertical" />
          </div>
        );
      },
    },
    {
      field: "overall_pos",
      headerName: "Possession",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: isMobile ? 10 : 80,
      description: "Possession",

      renderHeader: (params) => <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/possession.png`} alt="Possession" className="w-10" />,

      renderCell: (params) => (
        <div>
          <div>{params.row.homeposs ?? "-"}%</div>
          <Divider />
          <div>{params.row.awayposs ?? "-"}%</div>
        </div>
      ),
    },
    {
      field: "overall_sot",
      headerName: "Shots On Target",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: isMobile ? 10 : 80,
      description: "Shots On Target",

      renderHeader: (params) => <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/sont.png`} alt="Shots On Target" className="w-10" />,

      renderCell: (params) => (
        <div>
          <div>{safeSplit(params.row.shotsont, "<br />", 0) ?? "-"}</div>
          <Divider />
          <div>{safeSplit(params.row.shotsont, "<br />", 1) ?? "-"}</div>
        </div>
      ),
    },
    {
      field: "overall_sofft",
      headerName: "Shots Off Target",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: isMobile ? 10 : 80,
      description: "Shots Off Target",

      renderHeader: (params) => <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/sofft.png`} alt="Shots Off Target" className="w-10" />,

      renderCell: (params) => (
        <div>
          <div>{safeSplit(params.row.shotofft, "<br />", 0) ?? "-"}</div>
          <Divider />
          <div>{safeSplit(params.row.shotofft, "<br />", 1) ?? "-"}</div>
        </div>
      ),
    },
    /* {
      field: "shotsInBox",
      headerName: "Shots In the Box",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: 10,

      renderHeader: (params) => (
        <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/sofft.png`} alt="Shots Off Target" />
      ),

      renderCell: (params) => (
        <div>
          <div>{params.row.sinbox}</div>
        </div>
      ),
    }, */
    {
      field: "overall_dattack",
      headerName: "Dangerous Attacks",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: isMobile ? 10 : 80,
      description: "Dangerous Attacks",

      renderHeader: (params) => <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/dattack.png`} alt="Dangerous Attacks" className="w-10" />,

      renderCell: (params) => (
        <div>
          <div>{safeSplit(params.row.dattack, "<br />", 0) ?? "-"}</div>
          <Divider />
          <div>{safeSplit(params.row.dattack, "<br />", 1) ?? "-"}</div>
        </div>
      ),
    },
    {
      field: "overall_attack",
      headerName: "Attacks",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: isMobile ? 10 : 80,
      description: "Attacks",

      renderHeader: (params) => <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/attack.png`} alt="Attacks" className="w-10" />,

      renderCell: (params) => (
        <div>
          <div>{safeSplit(params.row.attack, "<br />", 0) ?? "-"}</div>
          <Divider />
          <div>{safeSplit(params.row.attack, "<br />", 1) ?? "-"}</div>
        </div>
      ),
    },
    {
      field: "overall_corners",
      headerName: "Corners",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: isMobile ? 10 : 80,
      description: "Corners",

      renderHeader: (params) => <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/corners.png`} alt="Corners" className="w-10" />,

      renderCell: (params) => (
        <div>
          <div>{params.row.homecorners}</div>
          <Divider />
          <div>{params.row.awaycorners}</div>
        </div>
      ),
    },
    {
      field: "overall_ycards",
      headerName: "Yellow Cards",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: isMobile ? 10 : 80,
      description: "Yellow Cards",

      renderHeader: (params) => <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/yellow.png`} alt="Yellow Cards" className="w-10" />,

      renderCell: (params) => (
        <div>
          <div>{safeSplit(params.row.ycards, "<br />", 0) ?? "-"}</div>
          <Divider />
          <div>{safeSplit(params.row.ycards, "<br />", 1) ?? "-"}</div>
        </div>
      ),
    },
    {
      field: "overall_rcards",
      headerName: "Red Cards",
      sortable: true,
      headerAlign: "center",
      align: "center",
      width: isMobile ? 10 : 80,
      description: "Red Cards",

      renderHeader: (params) => <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/red.png`} alt="Red Cards" className="w-10" />,

      renderCell: (params) => (
        <div>
          <div>{safeSplit(params.row.rcards, "<br />", 0) ?? "-"}</div>
          <Divider />
          <div>{safeSplit(params.row.rcards, "<br />", 1) ?? "-"}</div>
        </div>
      ),
    },
  ];

  const [selectedRow, setSelectedRow] = React.useState<TableRow | null>(null);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleRowClick = (row: TableRow) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: 600,
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: {
              lg: "80%",
              xs: "100%",
            },
          }}
        >
          <DataGrid
            rows={isLoggedIn ? rows : []}
            columns={columns}
            showCellVerticalBorder
            slots={{
              noRowsOverlay: isLoggedIn ? CustomNoRowsOverlay : LogInRowsOverlay,
              toolbar: GridToolbar,
            }}
            //checkboxSelection
            hideFooter
            //loading
            getRowClassName={(params) => ((params.indexRelativeToCurrentPage || 0) % 2 === 0 ? "data-grid-row-odd" : "")}
            onRowClick={(params) => handleRowClick(params.row as TableRow)}
          />
        </Box>
      </Box>
      {open && selectedRow && (
        <div>
          <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
            <DialogTitle align="center">
              {selectedRow.homet} vs {selectedRow.awayt}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <MatchChart team1={parseDataset(selectedRow, 0)} team2={parseDataset(selectedRow, 1)} color1="#FF5733" color2="#337BFF" team1_name={selectedRow.homet} team2_name={selectedRow.awayt} />
                <Alert icon={false} color="info" sx={{ marginTop: 2 }} variant="standard">
                  <AlertTitle>Screener Score</AlertTitle>
                  <Typography variant="body2" paragraph align="left" marginTop={1}>
                    <strong>SS #1:</strong> {selectedRow.hpi1} - {selectedRow.api1}
                    <Divider />
                    <strong>SS #2:</strong> {selectedRow.hpi2} - {selectedRow.api2}
                  </Typography>
                </Alert>
                <Typography variant="h6" paragraph align="center" marginTop={2}>
                  Last 10 minutes:
                </Typography>
                <Typography variant="body1" paragraph align="left" marginTop={1}>
                  <strong>Posession:</strong> {safeSplit(selectedRow["10poss"], "<br />", 0)} - {safeSplit(selectedRow["10poss"], "<br />", 1)}
                  <br />
                  <Divider />
                  <strong>Shots On Target:</strong> {selectedRow["10hshotsont"]} - {selectedRow["10ashotsont"]}
                  <br />
                  <strong>Shots Off Target:</strong> {safeSplit(selectedRow["10shotofft"], "<br />", 0)} - {safeSplit(selectedRow["10shotofft"], "<br />", 1)}
                  <br />
                  <Divider />
                  <strong>Dangerous Attacks:</strong> {safeSplit(selectedRow["10dattack"], "<br />", 0)} - {safeSplit(selectedRow["10dattack"], "<br />", 1)}
                  <br />
                  <Divider />
                  <strong>Corners:</strong> {safeSplit(selectedRow["10corners"], "<br />", 0)} - {safeSplit(selectedRow["10corners"], "<br />", 1)}
                  <br />
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} fullWidth>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default MatchesTable;
