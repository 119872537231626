import React, { useEffect, useRef } from "react";
import {
  RadarController,
  RadialLinearScale,
  Title,
  BarController,
} from "chart.js";
import Chart from "chart.js/auto";
import { getRelativePosition } from "chart.js/helpers";
import { Radar, Bar } from "react-chartjs-2";
let delayed: boolean;

Chart.register(BarController, RadialLinearScale, Title);
type Dataset = {
  possession: number;
  shotsOnTarget: number;
  shotsOffTarget: number;
  attacks: number;
  dangerousAttacks: number;
  corners: number;
  yellowCards: number;
  redCards: number;
};

type MatchChartProps = {
  team1: Dataset;
  team2?: Dataset;
  color1: string;
  color2?: string;
  team1_name: string;
  team2_name?: string;
};

const MatchChart: React.FC<MatchChartProps> = ({
  team1,
  team2,
  color1,
  color2,
  team1_name,
  team2_name,
}) => {
  const datasets = [
    {
      label: team1_name,
      data: Object.values(team1),
      borderColor: color1,
      backgroundColor: color1 + "33", // Adding transparency
    },
    team2 && {
      label: team2_name,
      data: Object.values(team2),
      borderColor: color2,
      backgroundColor: color2 ? color2 + "33" : undefined, // Adding transparency
    },
  ].filter(Boolean) as any; // Casting to any to avoid TypeScript error

  const data = {
    labels: [
      "Possession",
      "SOT",
      "SOFFT",
      "Attacks",
      "Dan. Attacks",
      "Corners",
      "Yellow Cards",
      "Red Cards",
    ],
    datasets,
  };

  return <Bar data={data} />;
};

export default MatchChart;
