import React, { useEffect, useRef, useState } from "react";
import "../../App.css";
import { db, analytics, logEvent } from "../../firebase";
import { DataSnapshot } from "firebase/database";
import { ref, onValue, off, update, get, DatabaseReference } from "firebase/database";
import "react-tabs/style/react-tabs.css";
import "../../styles.css";
import MatchesTable from "../MatchesTable";
import FAQSection from "../FAQSection";
import TelegramFiltersMessage from "../TelegramFIltersMessage";
import Menu from "../Menu";
import KeyIcon from "@mui/icons-material/Key";
import EmailIcon from "@mui/icons-material/Email";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LaunchIcon from "@mui/icons-material/Launch";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Item from "@mui/material/ListItem"; // Grid version 2
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import Chip from "@mui/material/Chip";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import queryString from "query-string";
import { BrowserRouter as Router, Route, useLocation, Routes } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Avatar,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const MainPage: React.FC = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  interface Plan {
    color: string;
    name: {
      en: string;
      de: string;
    };
    description: {
      en: string;
      de: string;
    };
    featuresText: {
      en: string[];
      de: string[];
    };
    priceText: {
      en: string;
      de: string;
    };
    btnText: {
      en: string;
      de: string;
    };
    planId: string;
    whop: string;
  }

  interface Licence {
    status: string;
  }

  interface Email {
    status: string;
  }

  interface Review {
    name: string;
    message: string;
    audioSrc: string;
    link: string;
    date: string;
  }

  interface Stats {
    users: number;
    cancelled_percentage: number;
    dbUpdate: number;
    youtube: string;
  }

  interface BetData {
    bet: string;
    id: number;
    isLive: boolean;
    league: string;
    money: number;
    odd: number;
    percentage: number;
    team: string;
  }

  if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
    window.location.href = window.location.href.replace('http:', 'https:');
  }

  React.useEffect(() => {
    const checkCookies = async () => {
      const cookies = document.cookie.split("; ");
      const licenseCookie = cookies.find((row) => row.startsWith("licencekey="));
      const emailCookie = cookies.find((row) => row.startsWith("email="));

      let refToCheck;
      let cookieValue: string;

      console.log(licenseCookie, emailCookie);

      if (licenseCookie) {
        cookieValue = licenseCookie.split("=")[1];
        refToCheck = ref(db, `/markus/betting-screener/licences/${cookieValue}`);
        get(refToCheck)
          .then((snapshot) => {
            const data = snapshot.val();

            if (data && data.status === "activated") {
              setIsLoggedIn(true);
            } else {
              // Cookie exists, but data is not activated. Delete cookie.
              setIsLoggedIn(false);
              document.cookie = `${licenseCookie ? "licencekey" : "email"}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (emailCookie) {
        cookieValue = emailCookie.split("=")[1];
        refToCheck = ref(db, `/markus/betting-screener/users`);
        get(refToCheck).then((snapshot) => {
          const usersData = snapshot.val();

          if (!usersData) {
            alert("Email not found.");
            document.cookie = `email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            return;
          }

          let userKey;
          for (const key in usersData) {
            // Only proceed if digistore exists for the user
            if (usersData[key]?.digistore && usersData[key].digistore.email.toLowerCase() === cookieValue.toLowerCase()) {
              userKey = key;
              break;
            }
          }

          if (userKey) {
            const userStatus = usersData[userKey].web;

            if (usersData[userKey].digistore.status === "active") {
              if (userStatus !== "blocked") {
                //window.location.reload();
                setIsLoggedIn(true);
              } else if (userStatus === "blocked") {
                update(ref(db, `/markus/betting-screener/users/${userKey}`), {
                  web: 0,
                });
              }
            } else {
              //window.location.reload();
              document.cookie = `email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
              setIsLoggedIn(false);
              alert("Your access has been restricted, please contact the support.");
            }
          } else {
            alert("Email not found.");
            document.cookie = `email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          }
        });
      } else {
        const url = new URL(window.location.href);
        const activate = url.searchParams.get("activate");

        if (activate) {
          LicenceKey = activate;
          handleSignIn();
        }
      }
    };

    checkCookies();
  }, []);

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);
  const [licenseKey, setLicenseKey] = useState<Licence | null>(null);
  const [email, setEmail] = useState<Email | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let LicenceKey = "";
  let Email = "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignIn = async () => {
    // Reference to the database
    let refToCheck: DatabaseReference;

    if (valueTab === 0) {
      // If signing in with email
      const usersRef = ref(db, `/markus/betting-screener/users`);

      get(usersRef).then((snapshot) => {
        const usersData = snapshot.val();

        if (!usersData) {
          alert("Email not found.");
          return;
        }

        let userKey;
        for (const key in usersData) {
          // Only proceed if digistore exists for the user
          if (usersData[key]?.digistore && usersData[key].digistore.email.toLowerCase() === Email.toLowerCase()) {
            userKey = key;
            break;
          }
        }

        if (userKey) {
          const userStatus = usersData[userKey].web;

          if (userStatus !== "blocked") {
            setIsLoggedIn(true);
            const newUserStatus = userStatus + 1;
            update(ref(db, `/markus/betting-screener/users/${userKey}`), {
              web: newUserStatus,
            });
            document.cookie = `email=${Email}`;
            console.log(document.cookie);
            window.location.reload();
          } else {
            alert("Your access has been restricted, please contact the support.");
          }
          /* if (userStatus === undefined) {
            update(ref(db, `/markus/betting-screener/users/${userKey}`), {
              web: 0,
            });
            document.cookie = `email=${Email}`;
            console.log(document.cookie);
            window.location.reload();
            setIsLoggedIn(true);
          } else if (userStatus === 0) {
            update(ref(db, `/markus/betting-screener/users/${userKey}`), {
              web: 1,
            });
            document.cookie = `email=${Email}`;
            console.log(document.cookie);
            window.location.reload();
            setIsLoggedIn(true);
          } else if (userStatus === 1) {
            update(ref(db, `/markus/betting-screener/users/${userKey}`), {
              web: 2,
            });
            document.cookie = `email=${Email}`;
            console.log(document.cookie);
            window.location.reload();
            setIsLoggedIn(true);
          } else if (userStatus === 2) {
            alert(
              "Your access has been restricted, please contact the support."
            );
            update(ref(db, `/markus/betting-screener/users/${userKey}`), {
              web: "blocked",
            });
            setIsLoggedIn(false);
            document.cookie = `email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          } else if (userStatus === "blocked") {
            alert(
              "Your access has been restricted, please contact the support."
            );
          } */
        } else {
          alert("Email not found.");
        }
      });
    } else {
      refToCheck = ref(db, `/markus/betting-screener/licences/${LicenceKey}`);
      get(refToCheck).then((snapshot: DataSnapshot) => {
        const data = snapshot.val();

        console.log(data);
        console.log(`|${data}|`);
        if (data) {
          if (data.status === "created") {
            console.log("created");
            update(refToCheck, { status: "activated" });
            setIsLoggedIn(true);
            document.cookie = `licencekey=${LicenceKey}`;
            console.log(document.cookie);
            window.location.reload();
            setOpen(false);
          } else if (data.status === "activated") {
            console.log("activated - block");
            alert("It is not allowed to use the same account on multiple devices. Your access has been limited, please contact the support to renew your access.");
            update(refToCheck, { status: "blocked" });
            setOpen(false);
          } else if (data.status === "blocked") {
            alert("Your access has been restricted, please contact the support.");
            setOpen(false);
          } else if (data.status === "expired") {
            alert("Your access has been expired, please contact the support.");
            setOpen(false);
          }
        } else {
          if (valueTab === 0) {
            alert("The email is not correct.");
          } else {
            alert("The license key is not correct.");
          }
        }
      });
    }
  };

  function scrollToElement(arg0: string): void {
    const target = document.getElementById(arg0);
    if (target) {
      window.scrollTo({ top: target.offsetTop, behavior: "smooth" });
    }
  }

  const [valueTab, setValueTab] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const isMobile = window.innerWidth <= 600;

  console.log(isLoggedIn);
  return (
    <div className="App">
      {/* Header */}
      <header className=" text-white px-10 flex justify-between items-center mb-16">
        <Menu isLoggedIn={isLoggedIn} onSignInClick={handleClickOpen} />
      </header>

      <section id="description" className="mt-6">
        <Container>
          {/* <h2 className="font-black text-5xl text-left">Betting Screener</h2> */}
          <br />
        </Container>
        <Container>
          {isLoggedIn ? (
            <Collapse in={openAlert}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                You are Signed In. Do not clean your browser data. Account sharing is not allowed.
              </Alert>
            </Collapse>
          ) : (
            <Alert
              severity="warning"
              action={
                <Button color="warning" size="small" onClick={handleClickOpen}>
                  Sign In
                </Button>
              }
            >
              To access the in-play data, you need to sign in
            </Alert>
          )}

          <Alert
            severity="info"
            action={
              <Button
                color="info"
                href="https://betting-screener.com/bot/"
                endIcon={<LaunchIcon />}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open
              </Button>
            }
            sx={{ marginTop: isLoggedIn ? 0 : 1 }}
          >
            NEW: Check This Out!
          </Alert>

          <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
            <DialogTitle>Sign In</DialogTitle>
            <DialogContent>
              <Tabs value={valueTab} onChange={handleChangeTab} centered>
                <Tab label="Email" icon={<EmailIcon />} />
                <Tab label="Licence Key" icon={<KeyIcon />} />
              </Tabs>

              {valueTab === 0 && (
                // Email Sign-In Content
                <div className="mt-5">
                  <DialogContentText>
                    Please enter your Email to sign in.
                    <Alert
                      severity="info"
                      sx={{ marginTop: 2 }}
                      /* action={
                        <Button
                          color="info"
                          size="small"
                          onClick={handleClickOpen}
                        >
                          Sign In
                        </Button>
                      } */
                    >
                      <AlertTitle>Whitelisted Emails</AlertTitle>
                      <Typography variant="body1" paragraph align="left">
                        We only grant access to emails that have been verified through our{" "}
                        <Link href="https://t.me/BettingScreenerAuthBot" target="_blank" rel="noopener noreferrer">
                          Telegram bot
                        </Link>{" "}
                        for added security.
                      </Typography>
                      <ListItemText primary="To verify your email:" />
                      <ListItemText
                        primary={
                          <span>
                            1. Open our{" "}
                            <Link href="https://t.me/BettingScreenerAuthBot" target="_blank" rel="noopener noreferrer">
                              Telegram bot
                            </Link>
                            .
                          </span>
                        }
                      />
                      <ListItemText
                        primary="2. Type in your email address and follow the
                        verification instructions."
                      />
                      <ListItemText
                        primary="3. Once verified, come back here and enter your
                        confirmed email to gain access."
                      />
                    </Alert>
                  </DialogContentText>
                  <TextField autoFocus margin="dense" id="email" label="Email" type="email" fullWidth variant="standard" value={email} onChange={(e) => (Email = e.target.value)} />
                </div>
              )}

              {valueTab === 1 && (
                // License Key Sign-In Content
                <div className="mt-5">
                  <DialogContentText>
                    Please enter your license key to sign in.
                    <Alert severity="warning" sx={{ marginTop: 2 }}>
                      <AlertTitle>Warning</AlertTitle>
                      <Typography variant="body1" paragraph align="left">
                        Once you activate your licence key, you will get access to in-play data on this device. It is only possible to access this data with the device used to activate the licence
                        key. Do not clear your browser data if you do not want to lose access.
                      </Typography>
                    </Alert>
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="license-key"
                    label="License Key"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={licenseKey}
                    onChange={(e) => (LicenceKey = e.target.value)}
                  />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSignIn}>Sign In</Button>
            </DialogActions>
          </Dialog>
        </Container>
      </section>

      {/* MatchesTable */}
      <section id="mainTable" className="bg-white py-4 m-auto">
        <div className="p-4">
          <MatchesTable isLoggedIn={isLoggedIn} />
        </div>
      </section>

      <section id="legendSection" className="bg-gray-100 py-4 m-auto mb-5">
        <Container>
          <h2 className="font-black text-5xl text-center mt-5">How it works?</h2>
          <Typography variant="h4" paragraph align="center" marginTop={4}>
            Legend
          </Typography>
          <Grid container justifyContent={"center"}>
            <Grid container>
              <Grid>
                <List>
                <ListItem>
                  <ListItemAvatar>
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/pi2.png`} alt="Screener Score 1" />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ marginLeft: 2 }}
                    primary="Screener Score 1 (SS 1)"
                    secondary={
                      <React.Fragment>
                        <Typography variant="body2" color="text.primary">
                          Uses data from the last 10 minutes.
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/attack.png`} alt="Attacks" />
                    </ListItemAvatar>
                    <ListItemText sx={{ marginLeft: 2 }} primary="Attacks" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/sont.png`} alt="Shots On Target" />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ marginLeft: 2 }}
                      primary="SOT"
                      secondary={
                        <React.Fragment>
                          <Typography variant="body2" color="text.primary">
                            Shots On Target
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/corners.png`} alt="Corners" />
                    </ListItemAvatar>
                    <ListItemText sx={{ marginLeft: 2 }} primary="Corners" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/yellow.png`} alt="Yellow Card" />
                    </ListItemAvatar>
                    <ListItemText sx={{ marginLeft: 2 }} primary="Yellow Card" />
                  </ListItem>
                </List>
              </Grid>
              <Grid>
                <List>
                <ListItem>
                  <ListItemAvatar>
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/pi1.png`} alt="Screener Score 2" />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ marginLeft: 2 }}
                    primary="Screener Score 2 (SS 2)"
                    secondary={
                      <React.Fragment>
                        <Typography variant="body2" color="text.primary">
                          Uses data from the last 10 minutes.
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/dattack.png`} alt="Dangerous Attacks" />
                    </ListItemAvatar>
                    <ListItemText sx={{ marginLeft: 2 }} primary="Dangerous Attacks" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/sofft.png`} alt="Shots Off Target" />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ marginLeft: 2 }}
                      primary="SOFFT"
                      secondary={
                        <React.Fragment>
                          <Typography variant="body2" color="text.primary">
                            Shots Off Target
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/possession.png`} alt="Possession" />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ marginLeft: 2 }}
                      primary="POSS"
                      secondary={
                        <React.Fragment>
                          <Typography variant="body2" color="text.primary">
                            Possession
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <img src={`${process.env.REACT_APP_PUBLIC_URL}/icons/red.png`} alt="Red Card" />
                    </ListItemAvatar>
                    <ListItemText sx={{ marginLeft: 2 }} primary="Red Card" />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
          {/* <Divider sx={{ marginY: 1 }} /> */}
        </Container>
      </section>

      <section id="dataSection" className="bg-white py-4 m-auto mb-5">
        <Container>
          <Typography variant="h4" paragraph align="center" marginY={6}>
            In-depth Information
          </Typography>
          <Grid container spacing={2}>
            <Grid lg={6}>
              <img src={`${process.env.REACT_APP_PUBLIC_URL}/deep_info.jpg`} alt="Screener Score 1" />
            </Grid>
            <Grid lg={6}>
              <Alert severity="success">
                <AlertTitle sx={{ textAlign: "left" }}>Hint</AlertTitle>
                <Typography variant="body1" paragraph align="left">
                  Click on the <strong>team cell</strong> within our comprehensive interface to unlock a dialog packed with in-depth information about the selected match.
                </Typography>
              </Alert>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Possession"
                    secondary={
                      <React.Fragment>
                        <Typography variant="body2" color="text.primary">
                          Understand the ball control dynamics and the ability of a team to dictate the game's tempo
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider sx={{ marginY: 1 }} />
                <ListItem>
                  <ListItemText
                    primary="Shots on Target (SOT)"
                    secondary={
                      <React.Fragment>
                        <Typography variant="body2" color="text.primary">
                          Gauge the accuracy and effectiveness of the team's shooting strategy
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider sx={{ marginY: 1 }} />
                <ListItem>
                  <ListItemText
                    primary="Shots Off Target (SOFFT)"
                    secondary={
                      <React.Fragment>
                        <Typography variant="body2" color="text.primary">
                          Evaluate the missed opportunities that could impact the match's final score
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider sx={{ marginY: 1 }} />
                <ListItem>
                  <ListItemText
                    primary="Attacks & Dangerous Attacks"
                    secondary={
                      <React.Fragment>
                        <Typography variant="body2" color="text.primary">
                          Analyze the offensive prowess and the ability to create significant goal-scoring opportunities
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider sx={{ marginY: 1 }} />
                <ListItem>
                  <ListItemText
                    primary="Corners"
                    secondary={
                      <React.Fragment>
                        <Typography variant="body2" color="text.primary">
                          Assess set-piece strength and how it contributes to the overall attacking strategy
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider sx={{ marginY: 1 }} />
                <ListItem>
                  <ListItemText
                    primary="Yellow and Red Cards"
                    secondary={
                      <React.Fragment>
                        <Typography variant="body2" color="text.primary">
                          Scrutinize the disciplinary aspects, such as fouls and misconduct, which might influence the game's dynamics
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider sx={{ marginY: 1 }} />
                <ListItem>
                  <ListItemText
                    primary="Screener Scores"
                    secondary={
                      <React.Fragment>
                        <Typography variant="body2" color="text.primary">
                          Explore specialized metrics that provide a quantitative measure of the attacking pressure exerted by each team
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section id="faqSection" className="bg-gray-100 py-4 m-auto mb-5">
        <Container>
          <h2 className="font-black text-5xl text-center mt-5 mb-10">Frequently Asked Questions (FAQ)</h2>
          <FAQSection />
        </Container>
      </section>

      {/* Footer */}
      <footer className="bg-[#232323] text-white py-12">
        <Container>
          <img src={`${process.env.REACT_APP_PUBLIC_URL}/logo/logo_full_light.png`} alt="Screener Score 1" className="w-1/5 mx-auto" />
          <p className="text-xs mt-4">Betting-Screener.com is not a betting platform and does not provide any form of wagering, only football statistical services.</p>
          <p className="text-xs">
            Betting-Screener.com provides this data as is with no warranty as to the accuracy, completeness or timeliness of such data and accepts no responsibility for any loss (direct or indirect)
            suffered by you as a result of your reliance on it.
          </p>
          {/* <p className="text-xs mt-2">
            <a href="https://betting-screener.com/impressum/">Imprint</a> - <a href="https://betting-screener.com/datenschutz/">Data Privacy</a>
          </p> */}
          <p className="text-xs mt-2">Gambling can be addictive. Please play responsibly</p>
          <p className="text-xs mt-2">Copyright Betting-Screener.com © 2023. All Rights Reserved</p>
        </Container>
      </footer>
    </div>
  );
};

export default MainPage;
