import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQSection: React.FC = () => {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Do we offer real-time data?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography align="left">
            Absolutely. We pride ourselves on delivering data in real-time,
            ensuring that there's no discernible delay. We understand the
            significance of timely information, especially in the dynamic world
            of sports analytics, and we've made it our mission to provide you
            with instantaneous insights.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>What is the Screener Score?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography align="left">
            The 'Screener Score' is a sophisticated algorithm we've devised to
            encapsulate the performance of football teams. It isn't just a
            rudimentary score; it's a calculated representation derived from a
            myriad of parameters. These parameters include Shots on Target
            (SOT), Shots off Target (SOFFT), possession percentages, and various
            attack metrics, to name a few. For a more granular breakdown, we
            invite you to explore our "How it works" section.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Do you provide betting advice?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography align="left">
            Our platform offers a wealth of data and insights, but it's pivotal
            to understand that we don't furnish betting advice. We are committed
            to presenting accurate and timely data, empowering you to make
            informed decisions. However, the interpretation of this data and any
            subsequent actions are the sole responsibility of the user. We
            always advocate for informed and responsible decision-making.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>How can users receive match-specific notifications?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography align="left">
            To receive notifications when a match meets certain criteria, users
            can utilize our Telegram bot.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>How many devices can I use at the same time?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography align="left">
            It is possible to use 2 devices on one account. We don't allow account sharing, so if such behavior is identified, the account will get banned.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FAQSection;
