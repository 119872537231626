import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBCTzcAWbx49nmwMZWn-45kTRV63TxGVZ8",
  authDomain: "bettting-2.firebaseapp.com",
  databaseURL:
    "https://bettting-2-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bettting-2",
  storageBucket: "bettting-2.appspot.com",
  messagingSenderId: "438389676571",
  appId: "1:438389676571:web:56a47a3597bd8edb8436be",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const analytics = getAnalytics(app);

export { database as db, analytics, logEvent };
