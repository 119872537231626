import "./App.css";
import "react-tabs/style/react-tabs.css";
import "./styles.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import MainPage from "./components/MainPages/MainPage";


function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/*"
          element={ <MainPage /> }
        />
      </Routes>
    </Router>
  );
}

export default App;
